.confirmDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(26, 26, 26, 0.6);
  z-index: 101;
}
.confirmDialog .confirmDialogBox {
  width: 100%;
  margin: 0 1.2rem;
  padding: 1.2rem;
  background-color: #fff;
  border-radius: 1.2rem;
}
.confirmDialog .confirmDialogBox .title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  color: #1A1A1A;
  word-break: break-all;
}
.confirmDialog .confirmDialogBox .content {
  margin-top: .4rem;
  font-size: .8rem;
  line-height: 1.2rem;
  color: #707070;
  word-break: break-all;
}
.confirmDialog .confirmDialogBox .footer {
  display: flex;
  margin-top: .8rem;
}
.confirmDialog .confirmDialogBox .footer :first-child {
  margin-right: .4rem;
}
