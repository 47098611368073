:global .messageContainer.slide-in-from-bottom {
  animation: message-move-in-from-bottom 0.25s cubic-bezier(0.5, 0, 0.15, 1);
}
:global .messageContainer.slide-out-to-bottom {
  animation: message-move-out-from-bottom 0.25s cubic-bezier(1, 0, 0.5, 1);
  animation-fill-mode: forwards;
}
:global .messageContainer.slide-in {
  animation: message-move-in 0.25s cubic-bezier(0.5, 0, 0.15, 1);
}
:global .messageContainer.slide-out {
  animation: message-move-out 0.25s cubic-bezier(1, 0, 0.5, 1);
  animation-fill-mode: forwards;
}
:global .messageContainer {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
}
:global .message {
  padding: .8rem .8rem;
  margin: .5rem .8rem;
  color: #1A1A1A;
  font-size: .7rem;
  line-height: 1rem;
  border-radius: .8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:global .message .left {
  display: flex;
  align-items: center;
}
:global .message .left > svg {
  margin-right: .5rem;
  vertical-align: middle;
}
:global .message .close {
  width: 1.2rem;
  height: 1.2rem;
}
:global .message.success {
  color: #fff;
  background: #00804a;
  box-shadow: 0px 12px 24px 0px rgba(28, 28, 28, 0.1);
}
:global .message.error {
  color: #fff;
  background: #d42e1c;
  box-shadow: 0px 12px 24px 0px rgba(28, 28, 28, 0.1);
}
:global .message.info {
  color: #fff;
  background: #3D3D3D;
  box-shadow: 0px 12px 24px 0px rgba(28, 28, 28, 0.1);
}
:global button.close {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
