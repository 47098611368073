.addressWrapper {
  position: relative;
  padding-bottom: 0.8rem;
}
.addressWrapper .mapWrapper {
  height: 5.6rem;
  height: 50vw;
  background: #f8f8f8;
  border-radius: .8rem;
}
.addressWrapper .copyBtn {
  margin-right: .6rem;
}
