/* Swiper.css */
:global {
  /* NewSwiper.css */
}
:global .new-swiper-container {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  touch-action: pan-y;
}
:global .new-swiper-container .new-swiper-wrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
:global .new-swiper-container .swiper-slide {
  flex-shrink: 0;
  width: 100%;
}
:global .new-swiper-container .pagination-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .6rem;
}
:global .new-swiper-container .pagination-dots .dot {
  position: relative;
  width: .2rem;
  height: .2rem;
  border-radius: 50%;
  background-color: #a3a3a3;
}
:global .new-swiper-container .pagination-dots .dot:not(:last-child) {
  margin-right: .2rem;
}
:global .new-swiper-container .pagination-dots .dot.active {
  width: 1rem;
  height: .2rem;
  border-radius: 1rem;
  background-color: #1A1A1A;
}
