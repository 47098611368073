.exceptionContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.exceptionContainer img {
  height: auto;
}
.exceptionContainer .title {
  margin-top: .8rem;
  padding: 0 1.8rem;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: .9rem;
  line-height: 1.3rem;
}
.exceptionContainer .subTitle {
  margin-top: .4rem;
  font-style: normal;
  font-weight: 400;
  font-size: .7rem;
  line-height: 1rem;
}
.exceptionContainer .link {
  margin-top: .4rem;
  color: #136FD8;
  font-weight: 500;
  font-size: .8rem;
  line-height: 1rem;
}
