.container {
  padding-top: .6rem;
  padding-bottom: .8rem;
}
.container .poiName,
.container .edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container .poiName {
  font-size: .9999rem;
  line-height: 1.4rem;
  font-weight: 500;
}
.container .poiName .editIcon {
  width: .6rem;
  height: .6rem;
}
.container .poiName .editBtn {
  margin-left: .4rem;
}
.container .subTitle {
  font-size: .7rem;
  color: #3D3D3D;
  font-weight: 400;
  line-height: 1rem;
}
.container .edit .left {
  position: relative;
  flex: 1;
  margin-right: 1rem;
}
.container .edit .left > input {
  width: 100%;
  border-radius: .8rem;
  border: 1px solid #00B14F;
  font-size: .8rem;
  line-height: 1.2rem;
  padding: .8rem;
  padding-right: 2.0rem;
  height: 2.8rem;
  box-sizing: border-box;
}
.container .edit .left > input:focus {
  outline: none;
  box-shadow: 0 0 0 1.066px #B1EABA;
}
.container .edit .left > input:disabled {
  background: #F5F5F5;
  border: 1px solid #E8E8E8;
  color: #707070;
}
.container .edit .left .circleBtn {
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #00B14F;
}
.container .extraText {
  display: inline-block;
  font-size: .6rem;
  color: #707070;
  line-height: .8rem;
  margin-top: .4rem;
}
.loading {
  z-index: 99;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
:global .ico-up {
  color: #fff;
}
