.detailItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .8rem 0;
}
.detailItem .left {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: .8rem;
  min-width: 0;
}
.detailItem .left .icon {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: #F5F5F5;
  margin-right: .6rem;
  flex-shrink: 0;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
