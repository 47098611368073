.suggestAnEditContainer {
  margin-top: .4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.suggestAnEditContainer .content {
  display: flex;
  align-items: center;
  color: #707070;
  flex: 1;
}
.suggestAnEditContainer .content .wraning-icon {
  display: inline-block;
  width: .7rem;
  height: .7rem;
}
.suggestAnEditContainer .content .text {
  font-size: .6rem;
  line-height: .8rem;
  margin-left: .2rem;
  flex: 1;
}
.suggestAnEditContainer .editBtn {
  font-size: .6rem;
  font-weight: 500;
  padding: 6px 12px 6px 10px;
  border: 1px solid #E8E8E8;
  border-radius: 2.4rem;
}
.suggestAnEditContainer .editBtn span {
  font-size: .6rem;
  line-height: .8rem;
  color: #1A1A1A;
}
