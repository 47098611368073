.inputContainer {
  position: relative;
}
.inputContainer .suffix {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(50%, -50%);
  color: #BFBFBF;
  font-size: .8rem;
  line-height: 1.2rem;
  width: 1.6rem;
  height: 1.6rem;
  z-index: 100;
}
.inputContainer .suffix.error {
  color: #D42E1C;
}
.textInput {
  width: 100%;
  border-radius: .8rem;
  font-size: .8rem;
  line-height: 1.2rem;
  padding: .8rem;
  padding-right: 2.0rem;
  height: 2.8rem;
  box-sizing: border-box;
  border: 1px solid #BFBFBF;
}
.textInput.error:focus {
  border: 1px solid #D42E1C;
  box-shadow: 2px 2px rgba(212, 46, 28, 0.15), -2px 2px rgba(212, 46, 28, 0.15), 2px -2px rgba(212, 46, 28, 0.15), -2px -2px rgba(212, 46, 28, 0.15);
}
.textInput:focus {
  outline: none;
  border: 1px solid #00B14F;
  box-shadow: 2px 2px rgba(0, 177, 79, 0.15), -2px 2px rgba(0, 177, 79, 0.15), 2px -2px rgba(0, 177, 79, 0.15), -2px -2px rgba(0, 177, 79, 0.15);
}
.textInput:disabled {
  background: #F5F5F5;
  border: 1px solid #E8E8E8;
  color: #707070;
}
