button.primaryBtn {
  width: 100%;
  height: 2.8rem;
  font-size: .8rem !important;
  font-weight: 600;
  line-height: 1.2rem;
  color: #fff;
  background: #00B14F;
  background-color: #00B14F;
  border: 0px;
  border-radius: 2.8rem;
  cursor: pointer;
  transition: all 0.3s;
}
button.primaryBtn.disabled {
  background: #BFBFBF;
  background-color: #BFBFBF;
  cursor: not-allowed;
}
