.poiDetailContainer {
  font-size: .7rem;
  width: 100%;
  padding: 0 1.2rem;
  background: #fff;
  box-sizing: border-box;
  padding-bottom: .3rem;
}
.poiDetailContainer .pageTitle {
  height: 4.4rem;
}
.poiDetailContainer button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  -webkit-tap-highlight-color: transparent;
}
.poiDetailContainer button:focus,
.poiDetailContainer button:active,
.poiDetailContainer button:hover {
  outline: none;
  background: none;
  box-shadow: none;
}
.poiDetailContainer :global .tags {
  display: flex;
  flex-direction: column;
}
.poiDetailContainer :global .tags .tags-item {
  margin-bottom: .2rem;
  display: flex;
  align-items: center;
  line-height: 1rem;
}
.poiDetailContainer :global .tags .tags-item:last-child {
  margin-bottom: 0;
}
.poiDetailContainer :global .tags .tags-item .tag-icon {
  margin-right: .3rem;
}
.poiDetailContainer :global .warning-line,
.poiDetailContainer :global .in-review-line {
  display: flex;
  align-items: center;
  height: .8rem;
  color: #707070;
  margin-top: .4rem;
}
.poiDetailContainer :global .warning-line .wraning-icon,
.poiDetailContainer :global .in-review-line .wraning-icon {
  display: inline-block;
  width: .7rem;
  height: .7rem;
}
.poiDetailContainer :global .warning-line span:nth-child(2),
.poiDetailContainer :global .in-review-line span:nth-child(2) {
  font-size: .6rem;
  line-height: .8rem;
  margin-left: .2rem;
}
.poiDetailContainer :global .device-border-bottom {
  position: relative;
}
.poiDetailContainer :global .device-border-bottom::after {
  position: absolute;
  content: '';
  background-color: #e8e8e8;
  display: block;
  width: 100%;
  height: 1px;
  /*no*/
  transform: scale(1, 0.5);
  bottom: 0;
  left: 0;
}
.poiDetailContainer :global .line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}
.poiDetailContainer :global .mapboxgl-marker {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}
:global .close-icon {
  width: .75rem !important;
  height: .75rem !important;
}
:global .close-icon-small {
  width: .5rem  !important;
  height: .5rem;
}
:global .close-icon-small.white path {
  fill: #fff;
}
:global .address-icon {
  width: 1.6rem !important;
  height: 1.6rem !important;
}
:global .edit-icon {
  width: .8rem !important;
  height: .8rem !important;
}
:global .edit-icon.disabled path {
  fill: #BFBFBF;
}
:global .inReview-icon {
  width: .7rem !important;
  height: .5rem !important;
}
:global .warning-icon {
  width: .7rem !important;
  height: .7rem !important;
}
:global .nudge-icon {
  width: 1.6rem !important;
  height: 1.6rem !important;
}
:global .nudge-reject-icon,
:global .nudge-accept-icon {
  width: 2rem !important;
  height: 2rem !important;
}
:global .nudge-reject-icon.submitting rect,
:global .nudge-accept-icon.submitting rect {
  fill: #005339;
}
:global .nudge-reject-icon.submitting path,
:global .nudge-accept-icon.submitting path {
  fill: #ffffff;
}
:global .up-icon {
  width: .55rem !important;
  height: .6rem !important;
}
:global .parent-poiName-icon {
  width: 1.6rem !important;
  height: 1.6rem !important;
}
:global .label-icon {
  width: 1.6rem !important;
  height: 1.6rem !important;
}
:global .saved-place-icon {
  width: 1.6rem !important;
  height: 1.6rem !important;
}
