.container {
  padding: .8rem 0;
}
.container .leftIcon {
  position: absolute;
  left: 0;
  width: 1.6rem;
  height: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
}
.container .swiperWrapper {
  width: 100%;
  box-sizing: border-box;
}
.container .swiperWrapper .qaItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.container .answers {
  flex-shrink: 0;
  margin-left: .7rem;
  font-size: 0;
}
.container .answers button:first-child {
  margin-right: .4rem;
}
