:global .mapboxgl-map {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: left;
}
:global .mapboxgl-map {
  border-radius: .8rem;
}
:global .mapboxgl-map:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
:global .map-attribution-icon {
  width: .9rem;
  background-color: transparent;
}
:global .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl-attrib.mapboxgl-compact:hover {
  padding-left: 28px !important;
}
:global .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
  width: 36px !important;
  height: 36px !important;
  background-image: url('../../../../../public/images/dax_map_i.png') !important;
  background-size: 1.2rem auto !important;
  background-repeat: no-repeat;
  background-color: transparent !important;
  background-position: left center !important;
}
